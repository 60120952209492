import React,{useState} from 'react';
import  { blogList }   from './Data';
import BlogItem from './BlogItem';
import {  useLocation } from 'react-router-dom';
import { Link } from "react-router-dom";
import { Card, CardHeader, CardBody, Typography ,Avatar } from "@material-tailwind/react";
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import { Helmet } from 'react-helmet';
import BlogsB from './BlogsB';


const Poet =() =>{
  const [blogs, setBlogs] = useState(blogList);
  const [visible, setVisible] = useState(6); 

  
  const handlerLoadMore = () => {
    setVisible((prevValue) => prevValue + 6);
  };

return(
  <>
  <Helmet>
  <title>Ankit Shukla: The Author at Nulfye</title>
  <meta name="description" content="I'm Ankit Shukla, a developer passionate about writing blogs on popular topics like AI content writing tools. Enjoy my articles and explore more. Good luck!" />
  </Helmet>
  <Grid item container >
  <Grid item xs={false} sm={false} md={1} lg={1} xl={1.5}/>
  <Grid item xs={12} sm={12} md={10} lg={10} xl={9}>
  <Paper  variant="outlined" align="center" className='bg-transparent mb-12 mt-6' >
  <Card color="transparent" shadow={false} className="w-full max-w-[75rem] p-2" >
  <CardHeader color="transparent" floated={false} shadow={false} className="flex items-center mb-2" >
  <Avatar alt="ankit shukla" src="/images/shukla-2.webp"  class='h-12 w-12 md:h-24 md:w-28 rounded-xl ' />
   <div>
  <Typography color="blue-gray" className='text-lg md:text-xl'><b>Ankit Shukla</b></Typography>

  <Typography color="blue-gray" className='text-sm md:text-lg ml-[24px] md:ml-[43px]'>Full Stack Developer</Typography>
  </div>
  </CardHeader>
  <CardBody className="mb-6 p-2">
  <Typography  align="left" className='text-[17px] md:text-lg lg:text-[20px]'>Hello I am Ankit Shukla A  developer, passionate of writing blog posts on most popular niches like Content Writing Tools Using Artificial Intelligence. Working as the web developer is my passion as i love to work on exciting projects. Hope the above article is helpful for you. Do not skip other posts. Good Luck!!! </Typography>
  </CardBody>
  </Card>
  </Paper>
  <div class="flex flex-col items-center">

  <h2 className='mb-16 mr-4 hover:underline md:mr-6 text-lg'>showing 6 out 60 blogs</h2></div>
  <div className="grid gap-8 grid-cols-1 md:grid-cols-2 lg:grid-cols-3 p-1">

  { blogs.reverse().slice(0,visible).map((blog) => (
    
  <BlogItem blog={blog} key ={blog.id}/>
  ))} 
    
  </div> 
  <div class="flex flex-col items-center">
  <div className="mt-8 mb-16 text-2xl md:mt-20 md:mb-20 lg:mb-28 md:text-3xl ">
  <Link onClick={handlerLoadMore}>View More</Link>
  </div></div>
  </Grid>
  < Grid item xs={false} sm={false} md={1} lg={2} xl={2} />
  </Grid>
  </>
  )
  }

export default Poet;