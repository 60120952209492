import React from 'react';
import Grid from '@mui/material/Grid';

const HomeB = () => {
    
return (
  <>
  <section>
  <Grid item container >
  <Grid item xs={false} sm={false} md={1} lg={2}/>
  <Grid item xs={12} sm={12} md={10} lg={8}>
  <div className="border-t border-gray-300">
  <h2 className='text-gray-800 text-center px-1 text-2xl md:text-3xl lg:text-4xl mt-8 md:mt-12 lg:mt-16 hover:underline'>What AI Article Writer Can Achieve</h2>

  <div className="grid gap-8 grid-cols-1 md:grid-cols-2 lg:grid-cols-3 p-2 mt-8 md:mt-10 md:mt-12" data-aos-id-blocks>

  <div className="relative flex flex-col items-center" data-aos="fade-up" data-aos-anchor="[data-aos-id-blocks]">
  <div  class="block max-w-sm p-4 bg-white border border-gray-200 rounded-lg shadow hover:bg-gray-100 dark:bg-gray-800 dark:border-gray-700 dark:hover:bg-gray-700">
  <img class="w-16 h-16 md:w-20 md:h-20  lg:w-24 lg:h-24 object-cover overflow-hidden "  src="../images/rocket.webp" alt="card-image" />
  <h3 class="mb-2 text-[21px] md:text-2xl font-bold tracking-tight text-gray-900 dark:text-white mt-4" align="left">Writes Articles Fast</h3>
  <p class="text-[18px] lg:text-[20px] text-gray-700 dark:text-gray-400" align="left">Creates well-written articles in just a few minutes, saving time and effort.  </p>
  </div>
  </div>

  <div className="relative flex flex-col items-center" data-aos="fade-up" data-aos-anchor="[data-aos-id-blocks]">
  <div  class="block max-w-sm p-6 bg-white border border-gray-200 rounded-lg shadow hover:bg-gray-100 dark:bg-gray-800 dark:border-gray-700 dark:hover:bg-gray-700">
  <img class="w-16 h-16 md:w-20 md:h-20  lg:w-24 lg:h-24 object-cover overflow-hidden "  src="../images/nlp.webp" alt="card-image" />

  <h3 class="mb-2 text-[21px] md:text-2xl font-bold tracking-tight text-gray-900 dark:text-white mt-4" align="left">Uses Simple Language</h3>
  <p class="text-[18px] lg:text-[20px] text-gray-700 dark:text-gray-400" align="left">Makes sure the writing is clear and easy for everyone to understand.  </p>
  </div>
  </div>


  <div className="relative flex flex-col items-center" data-aos="fade-up" data-aos-anchor="[data-aos-id-blocks]">
  <div  class="block max-w-sm p-6 bg-white border border-gray-200 rounded-lg shadow hover:bg-gray-100 dark:bg-gray-800 dark:border-gray-700 dark:hover:bg-gray-700">
  <img class="w-16 h-16 md:w-20 md:h-20  lg:w-24 lg:h-24 object-cover overflow-hidden "  src="../images/writing.webp" alt="card-image" />

  <h3 class="mb-2 text-[21px] md:text-2xl font-bold tracking-tight text-gray-900 dark:text-white mt-4" align="left">Adapts Writing Style</h3>
  <p class="text-[18px] lg:text-[20px] text-gray-700 dark:text-gray-400" align="left"> write in different tones, such as formal, casual, or creative, to match your needs.</p>
  </div>
  </div>

  <div className="relative flex flex-col items-center" data-aos="fade-up" data-aos-anchor="[data-aos-id-blocks]">
  <div  class="block max-w-sm p-6 bg-white border border-gray-200 rounded-lg shadow hover:bg-gray-100 dark:bg-gray-800 dark:border-gray-700 dark:hover:bg-gray-700">
  <img class="w-16 h-16 md:w-20 md:h-20  lg:w-24 lg:h-24 object-cover overflow-hidden "  src="../images/proofreading.webp" alt="card-image" />

  <h3 class="mb-2 text-[21px] md:text-2xl font-bold tracking-tight text-gray-900 dark:text-white mt-4" align="left">Corrects Mistakes</h3>
  <p class="text-[18px] lg:text-[20px] text-gray-700 dark:text-gray-400" align="left">Fixes spelling, grammar, and punctuation errors automatically.  </p>
  </div>
  </div>

  <div className="relative flex flex-col items-center" data-aos="fade-up" data-aos-anchor="[data-aos-id-blocks]">
  <div  class="block max-w-sm p-6 bg-white border border-gray-200 rounded-lg shadow hover:bg-gray-100 dark:bg-gray-800 dark:border-gray-700 dark:hover:bg-gray-700">
  <img class="w-16 h-16 md:w-20 md:h-20  lg:w-24 lg:h-24 object-cover overflow-hidden "  src="../images/fresh-idea.webp" alt="card-image" />

  <h3 class="mb-2 text-[21px] md:text-2xl font-bold tracking-tight text-gray-900 dark:text-white mt-4" align="left">Generates Fresh Ideas</h3>
  <p class="text-[18px] lg:text-[20px] text-gray-700 dark:text-gray-400" align="left"> Suggests new topics and ideas for writing when you're stuck.  
  </p>
  </div>
  </div>

  <div className="relative flex flex-col items-center" data-aos="fade-up" data-aos-anchor="[data-aos-id-blocks]">
  <div  class="block max-w-sm p-6 bg-white border border-gray-200 rounded-lg shadow hover:bg-gray-100 dark:bg-gray-800 dark:border-gray-700 dark:hover:bg-gray-700">
  <img class="w-16 h-16 md:w-20 md:h-20  lg:w-24 lg:h-24 object-cover overflow-hidden "  src="../images/original.webp" alt="card-image" />

  <h3 class="mb-2 text-[21px] md:text-2xl font-bold tracking-tight text-gray-900 dark:text-white mt-4" align="left">Ensures Originality</h3>
  <p class="text-[18px] lg:text-[20px] text-gray-700 dark:text-gray-400" align="left"> Produces unique content without copying from other sources.  </p>
  </div>
  </div>

  <div className="relative flex flex-col items-center" data-aos="fade-up" data-aos-anchor="[data-aos-id-blocks]">
  <div  class="block max-w-sm p-6 bg-white border border-gray-200 rounded-lg shadow hover:bg-gray-100 dark:bg-gray-800 dark:border-gray-700 dark:hover:bg-gray-700">
  <img class="w-16 h-16 md:w-20 md:h-20  lg:w-24 lg:h-24 object-cover overflow-hidden "  src="../images/languages.webp" alt="card-image" />

  <h3 class="mb-2 text-[21px] md:text-2xl font-bold tracking-tight text-gray-900 dark:text-white mt-4" align="left">Supports Multiple Languages</h3>
  <p class="text-[18px] lg:text-[20px] text-gray-700 dark:text-gray-400" align="left">Can write in <a class='coloring' href='/blog/the-role-of-ai-in-multilingual-content-creation-writing-for-a-global-audience/' target='_blank'>various languages</a> to reach a wider audience.    </p>
  </div>
  </div>

  <div className="relative flex flex-col items-center" data-aos="fade-up" data-aos-anchor="[data-aos-id-blocks]">
  <div  class="block max-w-sm p-6 bg-white border border-gray-200 rounded-lg shadow hover:bg-gray-100 dark:bg-gray-800 dark:border-gray-700 dark:hover:bg-gray-700">
  <img class="w-16 h-16 md:w-20 md:h-20  lg:w-24 lg:h-24 object-cover overflow-hidden "  src="../images/search-engine-optimization.webp" alt="card-image" />

  <h3 class="mb-2 text-[21px] md:text-2xl font-bold tracking-tight text-gray-900 dark:text-white mt-4" align="left">Optimizes for Search Engines</h3>
  <p class="text-[18px] lg:text-[20px] text-gray-700 dark:text-gray-400" align="left">Adds essential keywords to make your article easy to find online.  
  </p>
  </div>
  </div>

  <div className="relative flex flex-col items-center" data-aos="fade-up" data-aos-anchor="[data-aos-id-blocks]">
  <div  class="block max-w-sm p-6 bg-white border border-gray-200 rounded-lg shadow hover:bg-gray-100 dark:bg-gray-800 dark:border-gray-700 dark:hover:bg-gray-700">
  <img class="w-16 h-16 md:w-20 md:h-20  lg:w-24 lg:h-24 object-cover overflow-hidden "  src="../images/summary-check.webp" alt="card-image" />

  <h3 class="mb-2 text-[21px] md:text-2xl font-bold tracking-tight text-gray-900 dark:text-white mt-4" align="left"> Summarizes Information</h3>
  <p class="text-[18px] lg:text-[20px] text-gray-700 dark:text-gray-400" align="left">Turns long texts into short, easy-to-read summaries.  
  </p>
  </div>
  </div>


  <div className="relative flex flex-col items-center" data-aos="fade-up" data-aos-anchor="[data-aos-id-blocks]">
  <div  class="block max-w-sm p-6 bg-white border border-gray-200 rounded-lg shadow hover:bg-gray-100 dark:bg-gray-800 dark:border-gray-700 dark:hover:bg-gray-700">
  <img class="w-16 h-16 md:w-20 md:h-20  lg:w-24 lg:h-24 object-cover overflow-hidden "  src="../images/create.webp" alt="card-image" />

  <h3 class="mb-2 text-[21px] md:text-2xl font-bold tracking-tight text-gray-900 dark:text-white mt-4" align="left">Creates Content for All Purposes</h3>
  <p class="text-[18px] lg:text-[20px] text-gray-700 dark:text-gray-400" align="left"> Useful for blogs, essays, stories, or professional reports. </p>
  </div>
  </div>

  </div>
  </div>
  </Grid>
  < Grid item xs={false} sm={false} md={1} lg={2}/>
  </Grid> 
  </section>
  </>

  )}

export default HomeB;