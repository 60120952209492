import React from 'react';
import { Link, useHistory} from 'react-router-dom';
import './blogitem.css';

const BlogT = ({ blog: {id, title, authorName ,createdAt ,cover,category },}) => {
  const history = useHistory();

  const handleIconButtonClick = pageUrl => {
    history.push(pageUrl)
  }

return (

  <>
  <div class="transform hover:scale-105 duration-500 ease-in-out">
  <div class="relative flex flex-col md:flex-row w-full my-6 bg-white shadow-sm border border-slate-200 rounded-lg w-96">
  <div class="relative p-2.5 md:w-2/5 shrink-0 overflow-hidden">
  
  <img src={cover} alt="card-image" class="h-[200px] w-full rounded-md md:rounded-lg object-cover" />
  </div>
  
  <div class="p-6">
  <div  class="mb-4 rounded-full bg-[#fef9c3] py-0.5 px-2.5 border border-transparent text-sm text-black transition-all shadow-sm w-20 text-center"><Link onClick={() => handleIconButtonClick (`/blogs/category/${category.toLowerCase()}`)}>{category}</Link></div>
  <Link to={`/blog/${id}/`}>
  <h3 class="mb-8 text-slate-800 text-xl font-medium text-2xl" align="left">{title}</h3></Link>

  <div>
  <div class="flex items-center justify-between">
  <div class="flex items-center">
  
  <img alt="Ankit-shukla-avatar" src="/images/ankit.webp" class="relative inline-block h-11 w-11 rounded-full" />
  <div class="flex flex-col text-md font-medium ml-3">
  <span class="text-slate-800"><a href="/author/technoankit/">{authorName}</a></span>
  <span class="text-slate-600 ml-1 text-sm">{createdAt}</span>

  </div></div></div></div></div></div></div>

  </>

  );
  }
    
export default BlogT;