import React, {useState} from 'react';
import  { blogList }   from './Data';
import Tabs from "./Tabs";
import Panel from "./Panel";
import BlogItem from './BlogItem';
import './poji.css';
import { Link } from "react-router-dom";
import Grid from '@mui/material/Grid';
import { Helmet } from 'react-helmet';


const myInfluencers = [11];
const IInfluencers = [22];
const JInfluencers = [33];
const KInfluencers = [44];
const LInfluencers = [55];


const Homi = () => {
  const [visible, setVisible] = useState(6);


  const handlerLoadMore = () => {
    setVisible((prevValue) => prevValue + 6);
  };


return (

    <>
    <Helmet>
    <title>Latest Blogs on Artificial Intelligence Writing - Nulfye</title>
    <meta name="description" content="Check out the latest blogs on AI writing! Learn how AI is changing content creation, improving writing processes, and boosting creativity in the digital world." />
    </Helmet>
    <Grid item container >
    <Grid item xs={false} sm={false} md={1} lg={1} xl={1.5}/>
    <Grid item xs={12} sm={12} md={10} lg={10} xl={9}>

    <Tabs>

    <Panel title="All Topics">
    <div className="grid gap-8 grid-cols-1 md:grid-cols-2 lg:grid-cols-3 p-1">
    
    { blogList.reverse().slice(0,visible).map(blog => {
    return <BlogItem blog={blog} key ={blog.id}/> ;           
    })}
    </div>
   <div class="flex flex-col items-center">
   <div className="mt-8 mb-16 text-2xl md:mt-20 md:mb-20 lg:mb-28 md:text-3xl">
   <Link onClick={handlerLoadMore}>View More</Link></div>
   </div>
    

    </Panel>

    <Panel title="Education">
    <div className="grid gap-8 grid-cols-1 md:grid-cols-2 lg:grid-cols-3 p-1">

    { blogList.filter(blog => myInfluencers.includes(blog.host_id)).reverse().slice(0,visible).map(blog => {
    return <BlogItem blog={blog} key ={blog.id}/>;
    })}
    </div>
    <div class="flex flex-col items-center">
    <div className="mt-12 mb-20 text-2xl md:mt-20 md:mb-28 md:text-3xl">
    <Link onClick={handlerLoadMore}>View More</Link></div>
    </div>
    </Panel>

    <Panel title="Marketing">
    <div className="grid gap-8 grid-cols-1 md:grid-cols-2 lg:grid-cols-3 md:mb-20 lg:mb-28 p-1">

    { blogList.filter(event => IInfluencers.includes(event.host_id)).reverse().slice(0,visible).map(blog => {
    return <BlogItem blog={blog} key ={blog.id}/>;
    })}
    </div>
    <div class="flex flex-col items-center">
    <div className=" mb-20 text-2xl  md:mb-28 md:text-3xl">
    <Link onClick={handlerLoadMore}>View More</Link>
    </div></div>
    </Panel>

    <Panel title="Business">
    <div className="grid gap-8 grid-cols-1 md:grid-cols-2 lg:grid-cols-3 md:mb-20 lg:mb-28 p-1">

    { blogList.filter(event => JInfluencers.includes(event.host_id)).reverse().slice(0,visible).map(blog => {
    return <BlogItem blog={blog} key ={blog.id}/>;
    })}
    </div>
    <div class="flex flex-col items-center">
    <div className=" mb-20 text-2xl  md:mb-28 md:text-3xl">
    <Link onClick={handlerLoadMore}>View More</Link>
    </div></div>
    </Panel>

    <Panel title="Technology">
    <div className="grid gap-8 grid-cols-1 md:grid-cols-2 lg:grid-cols-3 p-1">

    { blogList.filter(blog => KInfluencers.includes(blog.host_id)).reverse().slice(0,visible).map(blog => {
    return <BlogItem blog={blog} key ={blog.id}/>;
    })}
    </div>

    <div class="flex flex-col items-center">
    <div className="mt-12 mb-20 text-2xl md:mt-20 md:mb-28 md:text-3xl">
    <Link onClick={handlerLoadMore}>View More</Link>
    </div></div>
    </Panel>

    <Panel title="Search Engine Optimization" >
    <div className="grid gap-8 grid-cols-1 md:grid-cols-2 lg:grid-cols-3 md:mb-20 lg:mb-28 p-1">

    { blogList.filter(event => LInfluencers.includes(event.host_id)).reverse().slice(0,visible).map(blog => {
    return <BlogItem blog={blog} key ={blog.id}/>;
    })}
    </div>
    </Panel>

    </Tabs>
    </Grid>
    < Grid item xs={false} sm={false} md={1} lg={1} xl={1.5} />
    </Grid>
    </>
  );
};

export default Homi;