import React, { useState } from 'react';
import Bla from './Bla';
import { bla } from './Cata';
import Grid from '@mui/material/Grid';
import { Typography, Button} from "@material-tailwind/react";

const HomeE = () => {
  const [blogs, setBlogs] = useState(bla);

return (
  <>

  <Grid item container >
  <Grid item xs={false} sm={false} md={1} lg={2}/>
  <Grid item xs={12} sm={12} md={10} lg={8}>
  <div className="border-t border-gray-300">

  <section>
  <h2 className='text-gray-800 text-center text-2xl md:text-3xl lg:text-4xl mt-8 md:mt-12 lg:mt-16 font-medium hover:underline'>Recent Blogs</h2>
  <p className='text-center text-lg md:text-xl lg:text-2xl mt-1 md:mt-2 mb-2'>Some new & fresh blogs from our blog section</p>
  <div className='mb-2 md:px-8'>
  <Bla blogs={blogs}/></div> 
   
  <div class="container py-10 px-10 mx-0 min-w-full flex flex-col items-center">

  <a href="/blogs/">
  <Button ripple={false} className="bg-[#ffd01a] mt-4 mb-4 shadow-none hover:scale-105 hover:shadow-none focus:scale-105 focus:shadow-none active:scale-100 ">
  <Typography color="black" className="font-large font-sans "> View All Articles </Typography>
  </Button>
  </a>
  </div>
  </section>
  </div>
  </Grid>
  < Grid item xs={false} sm={false} md={1} lg={2} />
  </Grid>

  </>
      
  )}

export default HomeE;