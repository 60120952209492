import React, {useState} from 'react';
import BlogItem from './BlogItem';
import { blogList } from './Data';
import Grid from '@mui/material/Grid';
import { Helmet } from 'react-helmet';

const ZInfluencers = [88];

const Sports =() =>{
  const [visible, setVisible] = useState(6); 
  

return (
  <> 
  <Helmet>
  <title>artificial intelligence in sports: Revolutionizing Performance with AI-Driven Analytics - Nulfye</title>
  <meta name="description" content="Discover how AI-driven analytics are transforming sports, enhancing performance, predicting outcomes, and revolutionizing strategies at every level of the game." />
  </Helmet>

  <Grid item container >
  <Grid item xs={false} sm={false} md={1} lg={1} xl={1.5}/>
  <Grid item xs={12} sm={12} md={10} lg={10} xl={9}>
  <div className='mb-20 md:mb-24 w-full bg-[#075985] h-28 md:h-36 lg:h-44'>
  <h2 className='text-white text-center text-3xl md:text-5xl mt-10 md:mt-17 lg:mt-20 p-10 md:p-12 lg:p-16'><strong>Sports</strong></h2> 
  </div>
  <div class="flex flex-col items-center">

  <h2 className='mb-8 md:mb-16 lg:mb-20 mr-4 hover:underline md:mr-6 text-lg'>showing 1 out 1 blogs</h2></div>
  <div className="grid gap-8 grid-cols-1 md:grid-cols-2 lg:grid-cols-3 mb-10 md:mb-20 lg:mb-28 p-1">

  { blogList.filter(blog => ZInfluencers.includes(blog.host_id)).reverse().slice(0,visible).map(blog => {
  return <BlogItem blog={blog} key={blog.id}/>
  })}

  </div>
 
  </Grid>
  < Grid item xs={false} sm={false} md={1} lg={2} xl={2}/>
  </Grid>
  </>
    
  )
  }
  
export default Sports;