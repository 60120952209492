import React from 'react';
import { Card, CardHeader, CardBody, CardFooter, Typography, Button } from "@material-tailwind/react";
import Avatar from '@mui/material/Avatar';
import { useHistory } from 'react-router-dom';

const Author = () => {
  const history = useHistory();

  const handleIconButtonClick = pageUrl => {
    history.push(pageUrl)
   }   

return(
  <>
  <Card className="w-full max-w-[53rem] ">
  <CardHeader color="transparent" floated={false} shadow={false} className="mx-0 flex items-center gap-2 pt-0" >

  <div className="flex w-full flex-col gap-0.5 items-center">
  <Avatar alt="ankit shukla" src="/images/shukla-2.webp" class='h-28 w-28 rounded-xl' />
  <Typography  color="blue-gray" className='text-left text-lg md:text-xl px-3 mt-1'><strong>Ankit Shukla</strong></Typography>
  <Typography color="blue-gray" className='text-left text-md md:text-lg px-3'>( Full Stack Developer )</Typography>
  </div></CardHeader>
      
  <CardBody>
  <Typography color="gray" className='text-left text-[16px] md:text-xl ml-2  md:mr-8 md:ml-8'> Hello I am Ankit Shukla A  developer, passionate of writing blog posts on most popular niches like Content Writing Tools Using Artificial Intelligence. Working as the web developer is my passion as i love to work on exciting projects. Hope the above article is helpful for you. Do not skip other posts. Good Luck.</Typography>
  </CardBody>

  <CardFooter className="pt-3 flex flex-col items-center "><a href= "/author/technoankit/">
  <Button ripple={false}  className="bg-orange-300  shadow-none hover:scale-105 hover:shadow-none focus:scale-105 focus:shadow-none active:scale-100 mb-8">
  <Typography color="black" className="font-large font-sans	"> View All Articles </Typography>
  </Button></a></CardFooter>
  </Card>
    
  </>
  
  )
  }

export default Author;