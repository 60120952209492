import React, { useRef } from 'react';
import emailjs from "@emailjs/browser";
import Swal from 'sweetalert2';

const ContactC = () => {
  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();
    emailjs .sendForm( "service_m9hyu5p", "template_z5tl5wq", form.current, "FB96w7y0h9sXI-dHg" )
    .then(
    (result) => { console.log(result.text); console.log("message sent"); },
    (error) => {
     console.log(error.text);
    });};

  const fireAlert = () => {
    Swal.fire({
    title: "Comment Sent Successfully!",
    icon: "success"
    });}
    
return(
  <>

  <section class="relative overflow-hidden bg-white py-2 dark:bg-dark "> 
  <div class="-mx-4 flex flex-wrap lg:justify-between">
  <div class="w-full px-4 lg:w-1.5/2 xl:w-12/12">

  <h1 className="text-gray-800 text-left text-2xl md:text-3xl mt-12 md:mt-16 lg:mt-20 mb:2 md:mb-4 hover:underline"><strong>Leave A Reply</strong></h1>
  <p className="text-left p-1 text-lg md:text-xl mt-1 md:mt-2 lg:mt-4  mb-2 md:mb-4"> Your email address will not be published. Required fields are marked *</p>
  <div class="relative rounded-lg bg-[#f9fafb] shadow-sm outlined mb-10 md:mb-20 lg:mb-28 max-w-[550px] max-h-[575px] p-2 md:p-8 lg:p-10">
  <form  ref={form} onSubmit={sendEmail} >

  <div class="mb-6">
  <input type="text" placeholder="Your Name" name="user_name"  class="w-full rounded border border-stroke px-[8px] py-2 text-base text-body-color outline-none focus:border-primary " />
  </div>

  <div class="mb-6">
  <input type="email" placeholder="Your Email" name="user_email" class="w-full rounded border border-stroke px-[8px] py-2 text-base text-body-color outline-none focus:border-primary " />
  </div>

  <div class="mb-6">
  <input type="text" placeholder="Your Phone" name="user_mobile" class="w-full rounded border border-stroke px-[8px] py-2 text-base text-body-color outline-none focus:border-primary " />
  </div>

  <div class="mb-6">
  <textarea rows="6" placeholder="Your Message" name="message" class="w-full resize-none rounded border border-stroke px-[8px] py-2 text-base text-body-color outline-none focus:border-primary "></textarea>
  </div>
  <div>
            
  <button type="submit" class="w-full rounded border border-primary bg-success p-3 text-white transition hover:bg-opacity-90"  value="send" onClick={fireAlert} > Post Comment </button>
  </div> </form> </div> </div> </div> </section>

  </>

  )
  }

export default ContactC;