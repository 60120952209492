import React from 'react';
import BlogItem from './BlogItem';
import { blogList } from './Data';
import Grid from '@mui/material/Grid';
import { Helmet } from 'react-helmet';

const LInfluencers = [55];

const Seo = () => {

return(
  
  <>
  <Helmet>
  <title>AI for SEO: Optimize Rankings with AI-Powered Content Writing Tools - Nulfye</title>
  <meta name="description" content="Master AI-driven content writing for SEO. Discover tips and strategies to create high-ranking, engaging content that boosts visibility and drives traffic." />
  </Helmet>
  <Grid item container >
  <Grid item xs={false} sm={false} md={1} lg={1} xl={1.5}/>
  <Grid item xs={12} sm={12} md={10} lg={10} xl={9}>

  <div className='mb-20 md:mb-24 w-full bg-[#075985] h-36 lg:h-44'>
  <h2 className='text-white text-center text-3xl md:text-5xl mt-10 md:mt-17 lg:mt-20 p-10 md:p-12 lg:p-16 '><strong>Search Engine Optimisation</strong></h2> 
  </div>
  <div class="flex flex-col items-center">

  <h2 className='mb-8 md:mb-16 lg:mb-20 mr-4 hover:underline md:mr-6 text-lg'>showing 5 out 5 blogs</h2></div>
  

  <div className="grid gap-8 grid-cols-1 md:grid-cols-2 lg:grid-cols-3 mb-10 md:mb-20 lg:mb-28 p-1">
  {blogList.filter(blog => LInfluencers.includes(blog.host_id)).reverse().slice(0)
  .map(blog => {
  return <BlogItem blog={blog} key ={blog.id}/>;
  })}
  </div>
  </Grid>
  < Grid item xs={false} sm={false} md={1} lg={2} xl={2} />
  </Grid>
  
  </>
      
  )
  }

export default Seo;