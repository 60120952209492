import React from "react";
import Nulfye from './Nulfye.webp';
import { Link, useHistory } from 'react-router-dom';
import {  Navbar, Typography } from "@material-tailwind/react";

const Header = () => {
  const [openNav, setOpenNav] = React.useState(false);
  const history = useHistory();

  React.useEffect(() => {
  window.addEventListener(
  "resize",
  () => window.innerWidth >= 960 && setOpenNav(false),
  );
  }, []);

  const hostButtonClick = pageUrl => {
  history.push(pageUrl)
  }
 
  const navList = (
    <ul className="flex  md:flex-row gap-8 md:gap-8  lg:mb-0 lg:mt-0  lg:flex-row lg:items-center lg:gap-12">
    <Typography as="li" variant="large" color="blue-gray" className="p-1 font-normal" >
    
    <Link onClick={() => hostButtonClick ("/")} className="flex items-center hover:underline">Home</Link>
    </Typography>
      
    <Typography
        as="li"
        variant="large"
        color="blue-gray"
        className="p-1 font-normal"
      >
       <Link onClick={() => hostButtonClick ("/blogs")} className="flex items-center hover:underline">
        
        Blogs
  </Link>
      </Typography>
      <Typography
        as="li"
        variant="large"
        color="blue-gray"
        className="p-1 font-normal"
      >
        <Link onClick={() => hostButtonClick ("/about")} className="flex items-center hover:underline">
        
        About Us
      </Link>
      </Typography>
    
    </ul>
  );
 
  return (
    <>

    <div className="w-full">
      <Navbar className="sticky top-0 h-max max-w-full bg-gray-50 rounded-none px-4 py-2 lg:px-8 lg:py-4">
        <div className="flex flex-wrap items-center justify-between ">
        <Typography
            as="a"
            href="/"
            className="mr-4 cursor-pointer py-1.5 font-medium"
          >
             <img
                alt="Nulfye Company Logo"
                src={Nulfye}
                className="h-16 w-auto "
              />
          
          </Typography>
          <div className="mr-4 text-black">{navList}</div>
        
          {/*<IconButton
            variant="text"
            className="md:hidden"
          >
                          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="size-6">
  <path stroke-linecap="round" stroke-linejoin="round" d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5" />
</svg>

            </IconButton>*/}
           </div>

      </Navbar>
    </div>

    </>
  );
}

export default Header;